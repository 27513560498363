import * as _isMap2 from "is-map";
var _isMap = _isMap2;
try {
  if ("default" in _isMap2) _isMap = _isMap2.default;
} catch (e) {}
import * as _isSet2 from "is-set";
var _isSet = _isSet2;
try {
  if ("default" in _isSet2) _isSet = _isSet2.default;
} catch (e) {}
import * as _isWeakmap2 from "is-weakmap";
var _isWeakmap = _isWeakmap2;
try {
  if ("default" in _isWeakmap2) _isWeakmap = _isWeakmap2.default;
} catch (e) {}
import * as _isWeakset2 from "is-weakset";
var _isWeakset = _isWeakset2;
try {
  if ("default" in _isWeakset2) _isWeakset = _isWeakset2.default;
} catch (e) {}
var exports = {};
var isMap = _isMap;
var isSet = _isSet;
var isWeakMap = _isWeakmap;
var isWeakSet = _isWeakset;

/** @type {import('.')} */
exports = function whichCollection( /** @type {unknown} */value) {
  if (value && typeof value === "object") {
    if (isMap(value)) {
      return "Map";
    }
    if (isSet(value)) {
      return "Set";
    }
    if (isWeakMap(value)) {
      return "WeakMap";
    }
    if (isWeakSet(value)) {
      return "WeakSet";
    }
  }
  return false;
};
export default exports;